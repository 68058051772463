$(function() {
   var parallax = require('./modules/parallax'),
       formCount = require('./modules/formcount'),
       realtymap = require('./modules/realtymap'),
       googlemap = require('./modules/googlemap'),
       filter = require('./modules/filter');

	svg4everybody();
	
  $(window).on('scroll', function(event) {
       var wScroll = window.pageYOffset;
       parallax.init(wScroll);
       if(wScroll >= 2000){
       	$('.arrow-up').show();
       }
       else if( wScroll < 2000){
       		$('.arrow-up').hide();
       }
    });
  //arrow up
  $('.arrow-up').on('click', function(e) {
  	e.preventDefault();
    $('html,body').animate({scrollTop: 0}, 600);
  });
//header menu
$('.header__menu-btn').on('click',  function(e) {
	e.preventDefault();
	var nav = $(this).siblings('.nav')
	nav.toggleClass('open');
});
$('.nav__link').on('click',  function(event) {
	if(!$(this).siblings('.nav__sub-list').hasClass('active')){		
		$('.nav__sub-list.active').removeClass('active').slideUp('400');
		$(this).siblings('.nav__sub-list').addClass('active').slideDown('400');
	}
	else{
		$(this).siblings('.nav__sub-list').removeClass('active').slideUp('400');
	}
});
$('.nav__close-btn').on('click', function(e) {
	e.preventDefault();
	$('.nav').removeClass('open');
});
//index anchros links
	$('.services__group-link, .hero__arrow, .hero__watch-btn, .recreation__nav-link').on('click', function(e) {
		e.preventDefault();
		var href = $(this).attr('href'),
			destionation = $(href).offset().top;
		$('html,body').animate({scrollTop: destionation}, 400);
	});
//index page youtube video
$('.case__video-link, .video__center').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });
//index page popup
$('.case__price-btn').on('click',  function(event) {
	event.preventDefault();

	$.magnificPopup.open({
		items: {
		    src: '.popup'
		},
		type: 'inline'
	})
});
//tabs
$('.tab__link').on('click', function(e){
	e.preventDefault();
	var tabId = $(this).attr('href'),
		tabs = $('.tabs');

	if(!$(this).hasClass('active')){
		$(this).addClass('active')
			.closest('.tab__item')
			.siblings()
			.find('.tab__link.active')
			.removeClass('active')
		tabs.find('.tab.active').removeClass('active');
		tabs.find(tabId).addClass('active');			

	}
});
//realty short and long page switcher
if($('.realty-single-double').length){
	$('.realty__switch-btn').on('click',  function(e) {
		e.preventDefault();
		var block = $(this).find('.realty__switch-block'), 
			textWrap = $(this).find('.realty__switch-text-wrap'),
			textActive = textWrap.find('.active'),
			priceWrap = $('.realty__price'),
			priceActive = priceWrap.find('.active'),
			formHide = $('#order').find('.order__form.hide');
		block.toggleClass('bottom');
		textActive.removeClass('active').siblings().addClass('active');
		priceActive.removeClass('active').siblings().addClass('active');
		formHide.removeClass('hide').siblings('.order__form').addClass('hide');
		formCount.init();
	});
}

$('.flowers__type-link').on('click', function(e) {
	e.preventDefault();
	var container = $(this).closest('.flowers__type');
	$(this).addClass('active').siblings().removeClass('active');
	formCount.init();
});
$('.order__form-checkbox').on('click',  function(e) {
	var text = $(this).siblings('.label-text').html(),
			name  = $(this).attr('name'),
			price = $(this).data('price');
	if($(this).is( ":checked")){
		$('.order__form-adds').append('<div data-name=' + name + ' data-price=' + price + '>'+ '+ ' + text + '</div>');
		formCount.init();
	}
	else{
		$('.order__form-adds').find('div').filter(function(){			
			return $(this).data('name') == name;
		}).remove();
		formCount.init();
	}
});
//order range
$(".order__slider").slider({
	min: 0,
	max: 10,
	step: 1,
	range: "min",
	value: 0,
	classes: {
	  "ui-slider": "order__slider",
	  "ui-slider-handle": "order__slider-handle",
	  "ui-slider-range": "order__slider-range"
	},	
	slide: function( event, ui ) {
		var count = $(this).siblings('.order__range').find('.count');		
    	count.val( "" + ui.value );
    	formCount.init();
  	}
})
$(".order__slider-height").slider({
	min: 50,
	max: 80,
	step: 10,
	range: "min",
	value: 50,
	classes: {
	  "ui-slider": "order__slider",
	  "ui-slider-handle": "order__slider-handle",
	  "ui-slider-range": "order__slider-range"
	},	
	slide: function( event, ui ) {
		var count = $(this).siblings('.order__range').find('.count');		
    	count.val( "" + ui.value );
    	formCount.init();
  	}
})
$(".order__slider-amount").slider({
	min: 1,
	max: 100,
	step: 1,
	range: "min",
	value: 1,
	classes: {
	  "ui-slider": "order__slider",
	  "ui-slider-handle": "order__slider-handle",
	  "ui-slider-range": "order__slider-range"
	},	
	slide: function( event, ui ) {
		var count = $(this).siblings('.order__range').find('.count');		
    	count.val( "" + ui.value );
    	formCount.init();
  	}
})
$(".order__slider-month").slider({
	min: 1,
	max: 12,
	step: 1,
	range: "min",
	value: 1,
	classes: {
	  "ui-slider": "order__slider",
	  "ui-slider-handle": "order__slider-handle",
	  "ui-slider-range": "order__slider-range"
	},	
	slide: function( event, ui ) {
		var count = $(this).siblings('.order__range').find('.month');		
    	count.val( "" + ui.value );
    	formCount.init();
  	}
})

$(".realty__filter__slider-rooms").slider({
	min: 1,
	max: 10,
	step: 1,
	range: "min",
	value: 1,
	classes: {
	  "ui-slider": "realty__filter__slider",
	  "ui-slider-handle": "realty__filter__slider-handle",
	  "ui-slider-range": "realty__filter__slider-range"
	},	
	slide: function( event, ui ) {
		var rooms = $(this).siblings('.realty__filter__range').find('.realty__rooms');		
    	rooms.val( "" + ui.value );    	
  	}
})

$(".realty__filter__slider-price").slider({
	range: true,
	min: 1,
	max: 3000,	
	values: [1, 3000], 
	classes: {
	  "ui-slider": "realty__filter__slider",
	  "ui-slider-handle": "realty__filter__slider-handle",
	  "ui-slider-range": "realty__filter__slider-range"
	},	
	slide: function( event, ui ) {
		var price = $(this).siblings('.realty__filter__range').find('.realty__price');		
    	price.val( ui.values[0] + "$" + " - " + ui.values[1] +"$" );    	
  	}
})
$('.realty__rooms').val("" + $('.realty__filter__slider-rooms').slider( "value" ))
$('.month__count').val("" + $('.order__slider-month').slider( "value" ))
$('.realty__price').val("" + $('.realty__filter__slider-price').slider("values", 0) + "$" + " - " + $(".realty__filter__slider-price").slider("values", 1) + "$");

$('.amount__count').val("" + $('.order__slider-amount').slider( "value" ))
$('.height__count').val("" + $('.order__slider-height').slider( "value" ))
$('.parents__count').val("" + $('.order__slider-parents').slider( "value" ))
$('.children__count').val("" + $('.order__slider-children').slider( "value" ))
formCount.init();
//tabs reviews
$('.reviews__form-link').on('click', function(e){
	e.preventDefault()
	$(this).toggleClass('open');
	$(this).siblings('.reviews__form').slideToggle('400', function(){

	});
})
var photoDate = $('#date').datepicker({
	onSelect: function(selectedDate){
		formCount.init();	
	},
});
var dates = $("#from, #to").datepicker({
  defaultDate: "+1w",
  language: 'ru',
  showOtherMonths:true, 
  selectOtherMonths:true, 
  onSelect: function(selectedDate){  	
    var option = this.id == "from" ? "minDate" : "maxDate",
    instance = $( this ).data( "datepicker" ),
    date = $.datepicker.parseDate(
    instance.settings.dateFormat || $.datepicker._defaults.dateFormat,
    selectedDate, instance.settings);

    dates.not(this).datepicker("option", option, date);    
    if(this.id == 'to' &&  !$('#from').val().length){
    	// return flase;
    } 
    else if(this.id == 'to' || this.id == 'from' &&  $('#to').val().length ){
    	formCount.init();
    }
  } 
});
// cброс языка на англ
// $.datepicker.setDefaults($.datepicker.regional[""])
// русския язык
jQuery(function ($) {
        $.datepicker.regional['ru'] = {
            closeText: 'Закрыть',
            prevText: 'Пред',
            nextText: 'След',
            currentText: 'Сегодня',
            monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            monthNamesShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
            dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            weekHeader: 'Нед',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional['ru']);
    });
//news slider
 $('.news__list').slick({
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 3,
	arrows: false,
	dots: true,
	dotsClass: 'news__dots',
	customPaging : function(slider, i) {
        return '<span class="news__dots-btn"></span>';
    },
    responsive: [
	    {
	      breakpoint: 768,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 2,
	        infinite: true,
	        dots: true
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1,
	        infinite: true,
	        dots: true
	      }
	    },


    ]
 })
//feedback slider
 $('.feedback__list').slick({
	arrows: false,
	dots: true,
	dotsClass: 'feedback__dots',
	customPaging : function(slider, i) {		
        return '<span class="feedback__dots-btn"></span>';
    },
 })


	//GOOGLE MAP
	if($('#googlemap').length){
		googlemap.init();
	}
	if($('#realty__map').length){
		realtymap.init();
	}
$('.shopping__filter-radio').on('change' , function(e){	
	filter.shopping();
})
$('.arenda__filter-radio').on('change' , function(e){	
	filter.auto();
})

$('.shopping__filter-reset').on('click',  function(e) {
	e.preventDefault();
	$('.shopping__list').find('.shopping__item').show();
	$('.shopping__filter-radio').prop('checked', false);
});
$('.arenda__filter-reset').on('click',  function(e) {
	e.preventDefault();
	$('.arenda__list').find('.arenda__item').show();
	$('.arenda__filter-radio').prop('checked', false);
});

// phtoos gallery
$('.gallery__wrap').not('.main').magnificPopup({
	delegate: 'a',
	type: 'image',
	mainClass: 'mfp-img-mobile',
	gallery: {
		enabled: true,
		navigateByImgClick: true,
		preload: [0,1] // Will preload 0 - before current, and 1 after the current image
	},
	image: {
		// tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'			
	}
});
});